<template>
  <div class="hero">
    <v-container class="container--lg-fluid">
      <v-row class="extra-padding d-flex align-center">
        <v-col cols="12" md="6" class="white--text section_col">
          <div class="mr-md-10 mr-lg-15 form_height">
            <div v-if="step == 1">
              <h2 class="fs--32" style="line-height: 1.2">
                De huidige energieprijzen swingen de pan uit!
              </h2>
              <p class="fs--16">
                Controleer binnen twee stappen of jij honderden euros per maand
                kan besparen met zonnepanelen.
              </p>
            </div>
            <h2 v-if="step == 2" class="fs--32" style="line-height: 1.2">
              De huidige energieprijzen swingen de pan uit!
            </h2>
            <h2 v-if="step > 2" class="fs--32" style="line-height: 1.2">
              GEFELICITEERD! Je komt in aanmerking voor het besparen met
              zonnepanelen!
            </h2>
            <v-card class="bg-white mt-3 form_padding pb-10 position-relative">
              <FormSection @steps="step++" />
            </v-card>
          </div>
          <img src="../assets/imgs/yellowBadge2024.png" class="yellowBadge" />
          <img src="../assets/imgs/greenBadge.png" class="greenBadge" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormSection from "./FormSection.vue";
export default {
  name: "Hero",
  components: { FormSection },
  data() {
    return {
      step: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url(../assets/imgs/header_bg.png);
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 959.98px) {
    background-position-x: 15%;
  }
}
.section_col {
  position: relative;
  // .form_height {
  //   height: 36.45rem;
  //   @media only screen and (max-width: 767.98px) {
  //     height: 80%;
  //   }
  // }
  .yellowBadge {
    position: absolute;
    right: -110px;
    bottom: 45px;
    width: 130px;
    @media only screen and (max-width: 959.98px) {
      display: none;
      // right: -5px;
      // bottom: 430px;
      // width: 28%;
    }
  }
  .greenBadge {
    position: absolute;
    right: -190px;
    bottom: -20px;
    width: 120px;
    @media only screen and (max-width: 959.98px) {
      display: none;
      // right: 280px;
      // bottom: 447px;
      // width: 20%;
    }
  }

  .form_padding {
    padding: 20px 40px 0px;
    @media (min-width: 992px) and (max-width: 1068.98px) {
      padding: 20px 20px 0px;
    }
    @media (max-width: 366.98px) {
      padding: 20px 17px 0px;
    }
  }
}
</style>
