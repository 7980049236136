<template>
  <v-container class="container--lg-fluid">
    <div
      class="extra-padding d-flex flex-column align-center flex-sm-row justify-center justify-sm-space-between align-items-center"
    >
      <img src="../assets/imgs/logo.png" width="230px" />
      <div class="d-sm-flex align-baseline text-center">
        <img src="../assets/imgs/Stars.png" />
        <p class="fs--14 mb-0 ml-2 grey--text lighten-1 text-sm-left">
          <span class="green--text font-weight-bold"
            >89.9% Klanttevredenheid</span
          >
          gebasseerd op 750+<br />
          reviews en 6,154 aangevraagde offertes.
        </p>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped></style>
