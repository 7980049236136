import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: {
        green: "4aab3d",
        orange: "ff7e00",
        gray: "9b9b9b",
        blue: "004cc0",
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 320,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
    scrollBarWidth: 24,
  },
});
