<template>
  <v-container fluid class="geschikt blue pb-0">
    <div class="extra-padding pt-10 px-sm-5 px-md-auto text-center">
      <div class="white--text">
        <h2 class="fs--40" style="line-height: 1.2">
          Is jouw woning geschikt:<br />
          Hoe werkt het?
        </h2>
        <p class="fs--14 mb-16">
          De huidige elektriciteitsprijzen swingen de pan uit. Wie de grote
          energieleveranciers te slim af wil<br class="d-none d-sm-block" />
          zijn, produceert zijn eigen energie. Ontdek hoe jij honderden euro's
          per maand bespaart!
        </p>
      </div>
      <v-row
        class="my-5 rounded white section_width mx-auto position-relative"
        no-gutters
      >
        <v-col cols="12" sm="4" class="position-relative">
          <div
            class="rounded text-left pa-6 pa-sm-4 pa-md-6"
            @click="selected = 1"
          >
            <img src="../assets/imgs/solar.png" width="50px" height="51px" />
            <h6 class="fs--16">Aanvraag</h6>
            <small class="gray--text"
              >Start je offerteaanvraag via ons formulier. Vertel ons over je
              situatie en ontvang tot 5 offertes installateurs uit jouw regio.
              Offertes aanvragen is gratis en vrijblijvend.</small
            >
            <v-btn
              @click="scrollToTop"
              color="orange"
              class="btn_font w--100 text-normal white--text mt-5 mb-3"
              v-if="selected == 1"
              ><v-icon small class="mr-2">mdi-arrow-right-circle</v-icon
              ><b> Start besparingscheck </b></v-btn
            >
          </div>
          <hr class="hr_tab" v-if="selected == 1" />
        </v-col>
        <v-col cols="12" sm="4" class="border-LR position-relative">
          <div
            class="rounded text-left pa-6 pa-sm-4 pa-md-6"
            @click="selected = 2"
          >
            <img src="../assets/imgs/energy.png" width="50px" height="51px" />
            <h6 class="fs--16">Vergelijk</h6>
            <small class="gray--text"
              >Onze zonnepanelen installateurs reageren meestal binnen 24 uur
              voor een plaatsbezoek. Ze meten de situatie op en werken een
              concrete prijsofferte uit op basis van jouw verbruik.</small
            >

            <v-btn
              @click="scrollToTop"
              color="orange"
              class="btn_font w--100 text-normal white--text mt-5 mb-3"
              v-if="selected == 2"
              ><v-icon small class="mr-2">mdi-arrow-right-circle</v-icon
              ><b> Start besparingscheck </b></v-btn
            >
          </div>
          <hr class="hr_tab" v-if="selected == 2" />
        </v-col>
        <v-col cols="12" sm="4" class="position-relative">
          <div
            class="rounded text-left pa-6 pa-sm-4 pa-md-6"
            @click="selected = 3"
          >
            <img src="../assets/imgs/wallet.png" width="50px" height="51px" />
            <h6 class="fs--16">Bespaar</h6>
            <small class="gray--text"
              >Zodra je de offertes hebt ontvangen, kies jij welk aanbod het
              beste bij je past. Wil je toch geen zonnepanelen? Geen probleem,
              je offerteaanvraag is geheel vrijblijvend en kosteloos.</small
            >
            <v-btn
              @click="scrollToTop"
              color="orange"
              class="btn_font w--100 text-normal white--text mt-5 mb-3"
              v-if="selected == 3"
              ><v-icon small class="mr-2">mdi-arrow-right-circle</v-icon
              ><b> Start besparingscheck </b></v-btn
            >
          </div>
          <hr class="hr_tab" v-if="selected == 3" />
        </v-col>
        <hr class="hr_tabs" />
      </v-row>
    </div>
    <v-container class="bg_bottom py-0 px-sm-2 px-0"
      ><img src="../assets/imgs/bottomBG.png" width="100%"
    /></v-container>
  </v-container>
</template>
<script>
export default {
  name: "Geschikt",
  data() {
    return { selected: 1 };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.geschikt {
  .section_width {
    width: 720px;
    @media (max-width: 959.98px) {
      width: auto;
    }
  }
  // @media (max-width: 959.98px) {
  //   .extra-padding {
  //     padding: 0 !important;
  //   }
  // }
  .btn_font {
    font-size: 12px;
    @media (min-width: 599.98px) and (max-width: 700px) {
      font-size: 9px;
    }
  }
}
.border-LR {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  border-top: none;
}
.hr_tabs {
  border-bottom: 3px solid #eaeaea;
  position: absolute;
  width: 100%;
  bottom: -3px;
  z-index: 9;
}
.hr_tab {
  border-bottom: 3px solid #4aab3d;
  position: absolute;
  width: 100%;
  z-index: 10;
  transition: 3s;
  animation: scale infinite linear 5s;
  animation-delay: 5.1s;
}
.bg_bottom {
  height: 0px;
  img {
    margin-top: -250px;
    z-index: 0;
    border-radius: 20px;
    @media (max-width: 959.98px) {
      margin-top: -130px;
    }

    @media (max-width: 599.98px) {
      margin-top: -90px;
    }
  }
}
</style>
