<template>
  <div>
    <div v-if="!submited && step < 5">
      <h4
        v-if="step == 1 || step == 2"
        class="text-decoration- orange--text text-center p-5 form_headin mb-3"
      >
        Stap {{ step }}
        <hr class="orange--text" />
      </h4>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="myForm" @submit.prevent="handleSubmit(onSubmit)">
          <!-- step 1 -->
          <div v-if="step == 1">
            <v-row>
              <div class="col-12 col-sm-7 pr-3 pr-sm-2">
                <label for="postcode" class="font-weight-bold fs--13"
                  >Je Postcode</label
                ><validation-provider
                  v-slot="{ errors }"
                  name="postcode"
                  :rules="{
                    required: true,
                    ErrorResponse: {
                      target: getErrorResponses.zip,
                    },
                  }"
                >
                  <v-text-field
                    :state="zip"
                    v-model="form.zip"
                    ref="zip"
                    outlined
                    label="2000"
                    id="postcode"
                    hide-details
                    :error-messages="errors"
                    solo
                    required
                  ></v-text-field>
                  <b-form-invalid-feedback class="text-red" v-if="zip == false">
                    postcode niet correct
                  </b-form-invalid-feedback></validation-provider
                >
              </div>
              <div class="col-12 col-sm-5 pl-3 pl-sm-2">
                <label for="house" class="font-weight-bold fs--13"
                  >Huisnummer</label
                >

                <validation-provider
                  v-slot="{ errors }"
                  name="Huisnummer"
                  :rules="{
                    required: true,
                    regex: /(\w?[0-9]+[a-zA-Z0-9\- ]*)/,
                    ErrorResponse: {
                      target: getErrorResponses.housenumber,
                    },
                  }"
                >
                  <v-text-field
                    :state="housenumber"
                    v-model="form.house_number"
                    outlined
                    :error-messages="errors"
                    ref="house_number"
                    id="house"
                    label="1"
                    solo
                    hide-details
                    required
                  ></v-text-field>
                  <b-form-invalid-feedback
                    class="text-red"
                    v-if="housenumber === false"
                  >
                    Huisnummer niet correct
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </v-row>
            <v-row>
              <div class="col-12">
                <label for="rentalOrOwner" class="fs--13 font-weight-bold"
                  >Heb je een huur- of koopwoning?</label
                >
                <div class="row">
                  <v-col class="pr-3 pr-sm-2">
                    <label class="btn btn-lg text-dark cursor-pointer"
                      ><input
                        required
                        value="3655"
                        type="radio"
                        name="toggle"
                        v-model="temp_form.koop"
                      /><span class="btnDiv">Koopwoning</span></label
                    ></v-col
                  >
                  <v-col class="pl-3 pl-sm-2">
                    <label class="btn btn-lg text-dark cursor-pointer"
                      ><input
                        required
                        value="3658"
                        type="radio"
                        name="toggle"
                        v-model="temp_form.koop"
                      /><span class="btnDiv">Huurwoning</span></label
                    ></v-col
                  >
                </div>
              </div>
            </v-row>
            <v-row>
              <div class="col-12">
                <label for="roof" class="fs--13 font-weight-bold"
                  >Wat voor dak heb je?
                </label>
                <div class="row no-gutters">
                  <v-col cols="4" class="pr-1">
                    <label
                      class="btn btn-lg pa-0 m-0 w-100 cursor-pointer d-block"
                      ><input
                        class="btnDiv"
                        v-model="temp_form.dak"
                        value="3646"
                        type="radio"
                        name="roof"
                        required
                      /><span
                        class="btnDiv2 fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                      >
                        <img
                          src="../assets/imgs/roofing.png"
                          class=""
                          width="55px"
                        />
                        Hellend dak
                      </span></label
                    >
                  </v-col>
                  <v-col cols="4" class="px-1">
                    <label
                      class="btn btn-lg pa-0 w-100 m-0 cursor-pointer d-block"
                      ><input
                        value="3649"
                        type="radio"
                        name="roof"
                        required
                        v-model="temp_form.dak"
                      /><span
                        class="btnDiv2 fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                      >
                        <img
                          src="../assets/imgs/garage-door.png"
                          class=""
                          width="55px"
                        />
                        Plat dak
                      </span></label
                    >
                  </v-col>
                  <v-col cols="4" class="pl-1">
                    <label
                      class="btn btn-lg pa-0 m-0 w-100 cursor-pointer d-block"
                      ><input
                        value="3652"
                        type="radio"
                        name="roof"
                        required
                        v-model="temp_form.dak"
                      /><span
                        class="btnDiv2 fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                      >
                        <div class="d-flex">
                          <img
                            src="../assets/imgs/roofing.png"
                            class="option-img"
                          />
                          <img
                            src="../assets/imgs/garage-door.png"
                            class="option-img"
                          />
                        </div>
                        Beide
                      </span></label
                    >
                  </v-col>
                </div>
              </div>
            </v-row>
          </div>
          <!-- step 2 -->
          <div v-else-if="step == 2">
            <div class="col-12 px-1">
              <label for="roof" class="fs--13 font-weight-bold"
                >Uit hoeveel personen bestaat je huishouden
              </label>
              <div class="row no-gutters">
                <v-col cols="4" class="pr-1 selectBoxHeight">
                  <label class="btn btn-lg col p-0 m-0"
                    ><input
                      class="btnDiv"
                      v-model="temp_form.personen"
                      value="3661"
                      type="radio"
                      name="persoon"
                      required
                    /><span
                      class="btnDiv2 fs--13 cursor-pointer font-weight-bold d-flex flex-column align-center justify-space-between"
                    >
                      <img
                        src="../assets/imgs/1person.png"
                        width="20"
                        class="mt-auto"
                      />
                      1 persoon
                    </span></label
                  >
                </v-col>
                <v-col cols="4" class="px-1 selectBoxHeight">
                  <label class="btn btn-lg col pl-1 pl-md-2 m-0"
                    ><input
                      value="3664"
                      type="radio"
                      name="persoon"
                      required
                      v-model="temp_form.personen"
                    /><span
                      class="btnDiv2 cursor-pointer fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                    >
                      <img
                        src="../assets/imgs/2person.png"
                        width="40"
                        class="mt-auto"
                      />
                      2 persoon
                    </span></label
                  >
                </v-col>
                <v-col cols="4" class="pl-1 selectBoxHeight">
                  <label class="btn btn-lg col pl-1 pl-md-2 m-0"
                    ><input
                      value="3667"
                      type="radio"
                      name="persoon"
                      required
                      v-model="temp_form.personen"
                    /><span
                      class="btnDiv2 cursor-pointer fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                    >
                      <img
                        src="../assets/imgs/3person.png"
                        width="60"
                        class="mt-auto"
                      />
                      3 persoon
                    </span></label
                  >
                </v-col>
                <v-col cols="4" class="pr-1 selectBoxHeight">
                  <label class="btn btn-lg col p-0 m-0 my-2"
                    ><input
                      value="3670"
                      type="radio"
                      name="persoon"
                      required
                      v-model="temp_form.personen"
                    /><span
                      class="btnDiv2 cursor-pointer fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                    >
                      <img
                        src="../assets/imgs/4person.png"
                        width="70"
                        class="mt-auto"
                      />
                      4 persoon
                    </span></label
                  >
                </v-col>
                <v-col cols="4" class="px-1 selectBoxHeight">
                  <label class="btn btn-lg col pl-1 pl-md-2 m-0 my-2"
                    ><input
                      value="3673"
                      type="radio"
                      name="persoon"
                      required
                      v-model="temp_form.personen"
                    /><span
                      class="btnDiv2 cursor-pointer fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                    >
                      <img
                        src="../assets/imgs/5person.png"
                        width="40"
                        class="mt-auto"
                      />
                      5 persoon
                    </span></label
                  >
                </v-col>
                <v-col cols="4" class="pl-1 selectBoxHeight">
                  <label class="btn btn-lg col pl-1 pl-md-2 m-0 my-2"
                    ><input
                      value="3676"
                      type="radio"
                      name="persoon"
                      required
                      v-model="temp_form.personen"
                    /><span
                      class="btnDiv2 cursor-pointer fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                    >
                      <img
                        src="../assets/imgs/meer5person.png"
                        width="60"
                        class="mt-auto"
                      />
                      meer dan 5
                    </span></label
                  >
                </v-col>
              </div>
            </div>
            <v-row>
              <div class="col-12 mt-2 pt-3 mb-4">
                <label for="rentalOrOwner" class="fs--13 font-weight-bold"
                  >Wens je ook meer informatie over een thuisbatterij?</label
                >
                <div class="row">
                  <v-col class="pr-2">
                    <label class="btn btn-lg text-dark cursor-pointer"
                      ><input
                        required
                        value="3679"
                        type="radio"
                        name="toggle"
                        v-model="temp_form.thuisbatterij"
                      /><span class="btnDiv">Ja</span></label
                    ></v-col
                  >
                  <v-col class="pl-2">
                    <label class="btn btn-lg text-dark cursor-pointer"
                      ><input
                        required
                        value="3682"
                        type="radio"
                        name="toggle"
                        v-model="temp_form.thuisbatterij"
                      /><span class="btnDiv">Nee</span></label
                    ></v-col
                  >
                </div>
              </div>
            </v-row>
          </div>
          <!-- main form -->
          <div v-else-if="3">
            <input
              type="checkbox"
              class="hidden-checkbox"
              v-model="bootCheck"
            />
            <v-row class="pb-2">
              <div class="col-12 col-sm-6 py-sm-2 py-0 pr-3 pr-sm-2">
                <label for="Voornaam" class="font-weight-bold fs--13"
                  >Voornaam</label
                ><validation-provider
                  v-slot="{ errors }"
                  name="Voornaam"
                  :rules="{
                    required: true,
                    ErrorResponse: {
                      target: getErrorResponses.firstname,
                    },
                  }"
                >
                  <v-text-field
                    :state="firstname"
                    v-model="form.firstname"
                    @input="handleFieldInput('firstname')"
                    outlined
                    label="Jan"
                    id="Voornaam"
                    ref="firstname"
                    hide-details
                    :error-messages="errors"
                    solo
                    required
                  ></v-text-field>
                  <b-form-invalid-feedback
                    class="text-red"
                    v-if="firstname === false"
                  >
                    Voornaam niet correct
                  </b-form-invalid-feedback></validation-provider
                >
              </div>
              <div class="col-12 col-sm-6 py-sm-2 py-0 pl-3 pl-sm-2">
                <label for="house" class="font-weight-bold fs--13"
                  >Achternaam</label
                >

                <validation-provider
                  v-slot="{ errors }"
                  name="Achternaam"
                  :rules="{
                    required: true,
                    ErrorResponse: {
                      target: getErrorResponses.lastname,
                    },
                  }"
                >
                  <v-text-field
                    v-model="form.lastname"
                    outlined
                    :state="lastname"
                    :error-messages="errors"
                    @input="handleFieldInput('lastname')"
                    ref="lastname"
                    id="Achternaam"
                    label="de Vries"
                    solo
                    hide-details
                    required
                  ></v-text-field>
                  <b-form-invalid-feedback
                    class="text-red"
                    v-if="lastname == false"
                  >
                    Achternaam niet correct
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </v-row>
            <label for="E-mail" class="font-weight-bold fs--13"
              >E-mail adres</label
            ><validation-provider
              v-slot="{ errors }"
              name="E-mail"
              :rules="{
                email: true,
                required: true,
                ErrorResponse: {
                  target: getErrorResponses.email,
                },
              }"
            >
              <v-text-field
                v-model="form.email"
                :state="email"
                @input="handleFieldInput('email')"
                outlined
                label="jdevries@gmail.com"
                ref="email"
                id="E-mail"
                hide-details
                :error-messages="errors"
                solo
                required
              ></v-text-field>
              <b-form-invalid-feedback class="text-red" v-if="email == false">
                E-mailadres niet correct
              </b-form-invalid-feedback></validation-provider
            >
            <label for="Telefoonnummer" class="font-weight-bold fs--13"
              >Telefoonnummer</label
            ><validation-provider
              v-slot="{ errors }"
              name="Telefoonnummer"
              :rules="{
                required: true,
                ErrorResponse: {
                  target: getErrorResponses.phone_number,
                },
              }"
            >
              <v-text-field
                :state="phone_number"
                v-model="form.phone_number"
                @input="handleFieldInput('phone_number')"
                outlined
                label="0412345678"
                ref="phone_number"
                id="Telefoonnummer"
                hide-details
                :error-messages="errors"
                solo
                required
              ></v-text-field>
              <b-form-invalid-feedback
                class="text-red"
                v-if="phone_number === false"
              >
                Telefoonnummer niet correct
              </b-form-invalid-feedback></validation-provider
            >
            <v-row class="py-2">
              <div class="col-12 col-md-7 pr-2">
                <label for="postcode" class="font-weight-bold fs--13"
                  >Je Postcode</label
                ><validation-provider
                  v-slot="{ errors }"
                  name="postcode"
                  :rules="{
                    required: true,
                    ErrorResponse: {
                      target: getErrorResponses.zip,
                    },
                  }"
                >
                  <v-text-field
                    :state="zip"
                    v-model="form.zip"
                    @input="handleFieldInput('zip')"
                    ref="zip"
                    outlined
                    label="postcode"
                    id="postcode"
                    hide-details
                    :error-messages="errors"
                    solo
                    required
                  ></v-text-field>
                  <b-form-invalid-feedback class="text-red" v-if="zip == false">
                    postcode niet correct
                  </b-form-invalid-feedback></validation-provider
                >
              </div>
              <div class="col-12 col-md-5 pl-2">
                <label for="house" class="font-weight-bold fs--13"
                  >Huisnummer</label
                >

                <validation-provider
                  v-slot="{ errors }"
                  name="Huisnummer"
                  :rules="{
                    required: true,
                    regex: /(\w?[0-9]+[a-zA-Z0-9\- ]*)/,
                    ErrorResponse: {
                      target: getErrorResponses.housenumber,
                    },
                  }"
                >
                  <v-text-field
                    :state="housenumber"
                    v-model="form.house_number"
                    @input="handleFieldInput('house_number')"
                    outlined
                    :error-messages="errors"
                    ref="house_number"
                    id="house"
                    label="1"
                    solo
                    hide-details
                    required
                  ></v-text-field>
                  <b-form-invalid-feedback
                    class="text-red"
                    v-if="housenumber === false"
                  >
                    Huisnummer niet correct
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </v-row>
            <label for="straatnaam" class="font-weight-bold fs--13"
              >Straatnaam</label
            ><validation-provider
              v-slot="{ errors }"
              name="straatnaam"
              :rules="{
                required: true,
                ErrorResponse: {
                  target: getErrorResponses.street,
                },
              }"
            >
              <v-text-field
                :state="street"
                v-model="form.street"
                @input="handleFieldInput('street')"
                outlined
                label="De Meier"
                id="straatnaam"
                ref="street"
                hide-details
                :error-messages="errors"
                solo
                required
              ></v-text-field>
              <b-form-invalid-feedback class="text-red" v-if="street === false">
                straatnaam niet correct
              </b-form-invalid-feedback></validation-provider
            >
          </div>

          <v-row class="pt-4">
            <span v-if="showValidationError" class="red--text fs--10">
              Please select at least one</span
            >

            <v-col lg="12" class="mt-">
              <v-btn
                size="lg"
                type="submit"
                color="orange"
                class="w--100 text-normal white--text"
              >
                <v-progress-circular
                  v-if="getLoader"
                  small
                  indeterminate
                  color="dark"
                  class="my-4"
                ></v-progress-circular>
                <span v-else>
                  <v-icon small class="mr-3">mdi-arrow-right-circle</v-icon
                  ><b v-if="step == 1"> Volgende stap </b>
                  <b v-else-if="step == 2">Controleer mijn gegevens</b>
                  <b v-else-if="step == 3">Begin met besparen</b>
                </span></v-btn
              >
            </v-col></v-row
          >
        </v-form>
        <p class="formText" v-if="step == 3">
          Alle gegevens zijn vertrouwelijk en worden behandeld in
          overeenstemming met de Europese wetgeving inzake de bescherming van de
          persoonlijke levenssfeer. Lees meer in ons
          <span class="cursor-pointer" @click="privacyModal = !privacyModal">
            privacybeleid
          </span>
          .
        </p>
      </validation-observer>
      <!-- modal -->
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card class="text-center pa-10">
            <h3>Je gegevens worden nu gecontroleerd</h3>
            <v-progress-circular
              indeterminate
              color="dark"
              class="my-4"
            ></v-progress-circular>

            <p class="ma-0">
              <v-icon v-if="checkMark1" color="green" class="mr-2">
                mdi-check </v-icon
              >Je postcode is gecheckt
            </p>
            <p class="ma-0">
              <v-icon v-if="checkMark2" color="green" class="mr-2">
                mdi-check </v-icon
              >Je woninggegevens zijn gecheckt
            </p>
            <p class="ma-0">
              <v-icon v-if="checkMark3" color="green" class="mr-2">
                mdi-check </v-icon
              >Je gezinssamenstelling is gecheckt
            </p>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <!-- thankyou -->
    <div v-if="submited">
      <h4
        class="text-decoration- orange--text text-center p-5 form_headin mb-3"
      >
        Bedankt voor uw aanvraag. We nemen zo snel mogelijk telefonisch contact
        met je op om je te informeren over hoe jij het beste kan besparen!
      </h4>
      <img
        v-if="profId !== null"
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.belgiebespaart.be/m/5675/e0d3f5cfb691/?event=6629&unique_conversion_id=${profId}`"
        style="width: 1px; height: 1px; border: 0px"
      />
    </div>
    <div v-if="step == 5">
      <h4
        class="text-decoration- orange--text text-center p-5 form_headin mb-3"
      >
        Sorry, maar helaas komt jouw woning op dit moment niet in aanmerking
        voor zonnepanelen offertes.
      </h4>
    </div>

    <!-- privacyModal -->
    <div class="text-center">
      <v-dialog v-model="privacyModal" width="800">
        <v-card class="text-left pa-3">
          <PrivacyModal />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green" text @click="privacyModal = false"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import PrivacyModal from "./PrivacyModal.vue";

import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");
import { mapActions, mapGetters } from "vuex";

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});
extend("required", {
  ...required,
  message: "verplicht",
});
extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
extend("regex", {
  ...regex,
  message: "{_value_} : ongeldig formaat",
});
extend("email", {
  ...email,
  message: "Email must be valid",
});
extend("ErrorResponse", {
  params: ["target"],
  validate(value, { target }) {
    console.log("errorrresponse", value, this.getErrorResponses, target);
    return target != null ? target : true;
  },
  message: "{_field_} must be valid",
});
export default {
  name: "FormFirst",
  components: {
    ValidationProvider,
    ValidationObserver,
    PrivacyModal,
  },

  data() {
    return {
      validationCheck: false,
      bootCheck: false,
      step: 1,
      dialog: false,
      privacyModal: false,
      viewModal: false,
      submited: false,
      checkMark1: false,
      checkMark2: false,
      checkMark3: false,
      showValidationError: false,
      // profileId: "",
      temp_form: {
        koop: "",
        dak: "",
        personen: "",
        thuisbatterij: "",
      },
      form: {
        language: "nl_BE",
        site_custom_name: "belgie_bespaart_zonnepanelen",
        site_custom_url: "https://zonnepanelen.belgiebespaart.be",
        publisher_id: "",
        site_subid: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        zip: "",
        house_number: "",
        street: "",
        answers: ["3637"],
      },
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    async onSubmit() {
      if (this.temp_form.koop == "3280") {
        this.step = 5;
      } else {
        if (
          (this.step == 1 &&
            this.temp_form.koop != "" &&
            this.temp_form.dak != "") ||
          (this.step == 2 &&
            this.temp_form.personen != "" &&
            this.temp_form.thuisbatterij != "") ||
          this.step == 3
        ) {
          this.showValidationError = false;
          this.step == 1
            ? this.form.answers.push(this.temp_form.koop, this.temp_form.dak)
            : "";
          this.step == 2
            ? this.form.answers.push(
                this.temp_form.personen,
                this.temp_form.thuisbatterij
              )
            : "";
          if (this.step != 3) {
            this.step++;
            this.$emit("steps");
          } else {
            const phoneNumberWithoutPunctuation =
              this.form.phone_number.replace(/[\s-]/g, "");

            let formattedPhoneNumber = phoneNumberWithoutPunctuation;

            if (formattedPhoneNumber.startsWith("0032")) {
              formattedPhoneNumber = `+${formattedPhoneNumber.substring(2)}`;
            } else if (formattedPhoneNumber.startsWith("32")) {
              formattedPhoneNumber = `+${formattedPhoneNumber}`;
            }

            console.log("Formatted phone number:", formattedPhoneNumber);

            // const phoneNumberWithoutPunctuation = this.form.phone_number
            //  .replace(/[\s-]/g, "")
            // .replace(/^00(\d+)$/, "$1");
            this.form.phone_number = formattedPhoneNumber;

            let resp = await this.postLead({
              ...this.form,
              bootCheck: this.bootCheck,
            });
            if (resp.success == true) {
              this.profileId = resp.profileid;
              this.submited = true;
              window.scrollTo(0, 0);
            }
          }
          const isValid = await this.$refs.myForm.validate();
          for (const key in this.getErrorResponses) {
            if (
              this.getErrorResponses.hasOwnProperty(key) &&
              this.getErrorResponses[key] === false
            ) {
              console.log("key", key);
              this.$refs[key].focus();
              this.$refs[key].blur();

              break;
            }
          }
        } else {
          this.showValidationError = true;
        }
      }
    },
  },
  watch: {
    step(newValue, oldValue) {
      if (oldValue == 2 && !this.viewModal) {
        this.step = 2;
        this.dialog = true;
        this.viewModal = true;
        setTimeout(() => {
          this.checkMark1 = true;
          setTimeout(() => {
            this.checkMark2 = true;
          }, 1000);
          setTimeout(() => {
            this.checkMark3 = true;
            setTimeout(() => {
              this.step = 3;
              this.dialog = false;
            }, 800);
          }, 2000);
        }, 1000);
      }
    },
  },
  created() {
    this.form.publisher_id = this.$route.query.site;
    this.form.site_subid = this.$route.query.oa_id;
  },
  computed: {
    ...mapGetters(["getLoader", "profId", "getErrorResponses"]),
    email() {
      if (this.form.email == "" && !this.getErrorResponses.email) {
        this.validationCheck = false;
        return null;
      }
      if (this.getErrorResponses.email == false) {
        this.validationCheck = false;
        return false;
      } else if (
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    firstname() {
      if (this.form.firstname == "" && !this.getErrorResponses.firstname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
      if (this.getErrorResponses.firstname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lastname() {
      if (this.form.lastname == "" && !this.getErrorResponses.lastname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;

      if (this.getErrorResponses.lastname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phone_number() {
      if (
        this.form.phone_number == "" &&
        !this.getErrorResponses.phone_number
      ) {
        this.validationCheck = false;
        return null;
      }
      let phoneRegex = /^(0|\+32|0032|00 32)([-\s]?\d[-\s]?){9}$/;
      if (this.getErrorResponses.phone_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    street() {
      if (this.form.street == "" && !this.getErrorResponses.street) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[a-zA-Z\s.,\-‘']+$/;
      if (this.getErrorResponses.street == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.street.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    housenumber() {
      if (this.form.house_number == "" && !this.getErrorResponses.housenumber) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[0-9A-Za-z\s\-]+$/;
      if (this.getErrorResponses.housenumber == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.house_number.match(streetRegex)) {
        this.validationCheck = true;
        return 1;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    zip() {
      if (this.form.zip == "" && !this.getErrorResponses.zip) {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4})$/;
      if (this.getErrorResponses.zip == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-red {
  color: red;
}
.hidden-checkbox {
  opacity: 0;
}
hr {
  width: 17%;
  margin: auto;
  border: 1px solid #ff7e00;
}
.form_heading {
  position: absolute;
  top: 10px;
}
.btnDiv,
.btnDiv2 {
  border: 2px solid #dbdbdb;
  border-radius: 6px;
  padding: 6px 12px;
  display: block;
  text-align: center;
  color: #dbdbdb;
  font-weight: 600;
}
.btnDiv2 {
  padding: 6px !important;
  min-height: 75px;
  color: #000;
}
label.btn {
  //   padding: 0;
}

label.btn input {
  opacity: 0;
  position: absolute;
}

label.btn span {
  //   text-align: center;
  //   color: #dbdbdb;
}

label.btn input:checked + .btnDiv {
  background-color: #4aab3d;
  color: #fff;
  border-color: #4aab3d;
}
label.btn input:checked + .btnDiv2 {
  background-color: transparent !important;
  color: #000;
  border-color: #4aab3d;
}
.col-12 {
  padding: 6px 12px !important;
}
.formText {
  line-height: 1;
  font-size: 9px;
  text-align: justify;
  margin-top: 5px;
}
.selectBoxHeight {
  height: 90px;
}
.option-img {
  width: 40px;
  @media only screen and (max-width: 599.98px) {
    width: 35px;
  }
}
</style>
