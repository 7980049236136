import { render, staticRenderFns } from "./Waroom.vue?vue&type=template&id=05cbe918&scoped=true"
import script from "./Waroom.vue?vue&type=script&lang=js"
export * from "./Waroom.vue?vue&type=script&lang=js"
import style0 from "./Waroom.vue?vue&type=style&index=0&id=05cbe918&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05cbe918",
  null
  
)

export default component.exports