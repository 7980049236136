<template>
  <div class="home">
    <Header />
    <Hero />
    <Waroom />
    <Geschikt />
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Geschikt from "../components/Geschikt.vue";
// @ is an alias to /src
import Header from "../components/Header.vue";
import Hero from "../components/Hero.vue";
import Waroom from "../components/Waroom.vue";

export default {
  name: "Home",
  components: {
    Header,
    Hero,
    Waroom,
    Geschikt,
    Footer,
  },
};
</script>
